import React from 'react'
import NetWiseClient from 'api/client'
import { SocketHandler } from 'api/TaskSocket'
import NetwiseSocket from 'api/NetwiseSocket'
import tm from 'analytics/TagManager'
import jwtDecode from 'jwt-decode'
import Client from 'api/client'
import Worker from 'context/auth-context/worker'
import endpoints from 'api/endpoints'
import { useSnackbar } from './snackbar-context'
import { Context } from 'react'
import { useAlerts } from './alert-context'
import { Task, SnackbarMessage, UserData } from 'types'

const contextWorker = Worker()
const client = new Client(contextWorker)
const AuthContext: Context<any> = React.createContext(null)
const socketHandler = new SocketHandler()
let netwiseSocket: NetwiseSocket

function AuthProvider(props: any) {
  const [user, setUser] = React.useState({
    loggedIn: false,
    hasAttemptedRefresh: false,
    userData: {},
  })
  const [allDataMode, setAllDataMode] = React.useState(false)
  const [adminMode, setAdminMode] = React.useState(false)
  const [buildVersion, setBuildVersion] = React.useState()
  const { sendAlert } = useAlerts()
  const { setSnackbarMessage: setMessage } = useSnackbar()

  const sessionsUrl = client.endpoints.sessions
  const pwResetUrl = client.endpoints.passwordResetRequest
  const pwResetSubmitUrl = client.endpoints.passwordResetSubmit

  client.showAlertMessage = sendAlert
  socketHandler.setNotificationFunction(setMessage)

  // Setting up NetwiseSocket connection
  if (!netwiseSocket) {
    netwiseSocket = new NetwiseSocket({
      endpoint: 'check_tasks/?auth-context',
      setNotification: setMessage,
    })
  }

  //  Client must be aware of how to log a user out, yet must also
  //  be tied to the application state
  client.logUserOut = async (): Promise<any> => {
    try {
      await client.post({ endpoint: client.endpoints.deleteTokenAPI })
      setUser((state) => {
        client.worker.setJwt(null)
        return { ...state, loggedIn: false }
      })
    } catch (e) {
      console.log(e)
    }
  }

  // Ultimately this will be passed to the application code and is used in the sign-in UI
  const login = async (username: string, password: string): Promise<any> => {
    try {
      const { responseData, ok, errorMessage } = await client.post({
        endpoint: client.endpoints.tokenAPI,
        data: {
          username,
          password,
        },
        auth: false,
      })
      const { access, message } = responseData

      if (message) {
        sendAlert({ message })
      }

      if (ok) {
        await client.worker.setJwt(access)
        const userData = await getUserData(access)
        await tm.fireQueuedEvents()
        tm.captureLogin(userData)

        client.setIsNetWiseAccount(userData?.account?.name === 'NetWise Data')
        setUser((state) => {
          return {
            ...state,
            loggedIn: true,
            userData: userData,
          }
        })

        setTimeout(() => client.worker.refreshToken(), 5 * 60 * 1000)
        return true
      }
      if (errorMessage) {
        sendAlert({ message: errorMessage })
      }
      return false
    } catch (e) {
      return false
    }
  }

  // Memoized fetch function to get user data. It is memoized since the outputs will
  // only change if setAllDataMode or setAdmin Mode is altered.
  const getUserData = React.useCallback(
    async (jwt: string): Promise<any> => {
      if (jwt) {
        const decodedJwt: any = jwtDecode(jwt)
        const [{ responseData: r1 }, { responseData: r2 }] = await Promise.all([
          client.get({ endpoint: client.endpoints.usersIdAPI(decodedJwt.user_id) }),
          client.get({ endpoint: client.endpoints.sessions }),
        ])

        const { results: userData } = r1
        const { results: modes } = r2
        const { data_mode_all, admin_mode } = modes
        setAllDataMode(data_mode_all)
        setAdminMode(admin_mode)

        // TODO: DEPRECATED: must replace for PLAT-1023
        userData.needsUpgrade =
          userData.account.type === 'analytics' && userData.account.credits === 0

        const isNetWiseAccount = userData?.account?.id === 1
        client.setIsNetWiseAccount(isNetWiseAccount)
        return {
          ...userData,
          assumer_id: !!decodedJwt.assumer_id,
          isNetWiseAccount,
        }
      }
      return {}
    },
    [setAllDataMode, setAdminMode]
  )

  // Often times in an SPA a user may enter a URL into the address bar directly or refreshes the page.
  // When this happens, this app code running on that page restarts, and the user is redirected to the login
  // screen since no access token is presently held in memory. This useEffect handles these common cases.
  React.useEffect(() => {
    async function refresh(): Promise<void> {
      if (!user.loggedIn && !user.hasAttemptedRefresh) {
        const jwt = await client.worker.refreshToken()

        const userData = await getUserData(jwt)
        tm.captureLogin(userData)

        setUser((state) => {
          return {
            ...state,
            loggedIn: jwt ? true : false,
            hasAttemptedRefresh: true,
            userData: userData,
          }
        })
      }
    }

    const theUrl = new URL(window.location.href)
    if (theUrl.pathname === '/login' && theUrl.search !== '') refresh()
    else setUser((state) => ({ ...state, hasAttemptedRefresh: true }))
  }, [user.hasAttemptedRefresh, user.loggedIn, getUserData])

  // Get app version data
  React.useEffect(() => {
    async function getVersionData() {
      try {
        const url = 'version/get_build_version'
        const { responseData } = await client.get({
          endpoint: url,
          auth: false,
        })
        setBuildVersion(responseData?.build_version)
      } catch (e) {
        console.log('Could not get version data')
        console.log(e)
      }
    }
    getVersionData()
  }, [])

  // Function toggle the All Data Mode in the UI
  async function toggleAllDataMode(): Promise<any> {
    const { responseData } = await client.post({
      endpoint: sessionsUrl,
      data: { data_mode_all: !allDataMode },
    })
    const { data_mode_all } = responseData.results
    setAllDataMode(data_mode_all)
  }

  // Function toggle the Admin Mode in the UI
  async function toggleAdminMode(): Promise<any> {
    const { responseData } = await client.post({
      endpoint: sessionsUrl,
      data: { admin_mode: !adminMode },
    })
    const { admin_mode } = responseData.results
    setAdminMode(admin_mode)
  }

  // Requesting a password reset for a given email address
  const requestReset = async (email: string): Promise<any> => {
    try {
      // Safeguard against sending emails when testing
      const [emailName] = email.toLowerCase().split('@')
      if (
        process.env.NODE_ENV !== 'production' &&
        ![
          'sage.lane',
          'lanesa',
          'yong.choi',
          'kofi.boadu',
          'dan.reale',
          'test.user',
          'qatest.pentestuser01',
          'qatest.pentestadm01'
        ].includes(emailName)
      ) {
        alert('Emails will not be sent outside of prod to non-netwise testers')
        throw new Error('Do not send emails outside of prod to non-netwise testers')
      }

      const response = await client.post({
        endpoint: pwResetUrl,
        data: { email },
        auth: false,
      })
      return response
    } catch (e) {
      console.log('Could not request reset at this time')
    }
  }

  // Submitting the users newly selected password
  const resetPassword = async (token: string, password: string): Promise<any> => {
    try {
      await client.post({
        endpoint: pwResetSubmitUrl,
        data: { token, password },
        auth: false,
      })
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  }

  // Perform actions on a users behalf requires you to first "assume" their id
  // Must refresh the token after assuming the id.
  const assumeUser = async (id: number) => {
    try {
      await client.post({
        endpoint: endpoints.assumeUser,
        data: { user_id: id },
        useCache: false,
      })
      await client.worker.refreshToken()
      window.location.reload()
    } catch (e) {
      console.log('Could not assume user role at this time.')
    }
  }

  // Unassuming the user
  const unassumeUser = async () => {
    try {
      await client.post({
        endpoint: endpoints.unassumeUser,
        data: {},
        useCache: false,
      })
      await client.worker.refreshToken()
      window.location.reload()
    } catch (e) {
      console.log('Could not unassume user role at this time.')
    }
  }

  return (
    <AuthContext.Provider
      value={{
        client: client,
        buildVersion: buildVersion,
        assumeUser: assumeUser,
        unassumeUser: unassumeUser,
        userData: user.userData,
        allDataMode: allDataMode,
        toggleAllDataMode: toggleAllDataMode,
        adminMode: adminMode,
        toggleAdminMode: toggleAdminMode,
        hasAttemptedRefresh: user.hasAttemptedRefresh,
        loggedIn: user.loggedIn,
        login: login,
        logout: client.logUserOut,
        requestReset: requestReset,
        resetPassword: resetPassword,
        addNotifierTask: (tasks: Task[]) => netwiseSocket.processTasks(tasks),
        socketHandler: socketHandler,
      }}
      {...props}
    />
  )
}

interface Auth {
  client: NetWiseClient
  buildVersion: string
  assumeUser: (id: number) => void
  unassumeUser: () => void
  user: any
  userData: UserData
  allDataMode: boolean
  toggleAllDataMode: () => void
  adminMode: boolean
  toggleAdminMode: () => void
  hasAttemptedRefresh: boolean
  loggedIn: boolean
  login: (email: string, password: string) => boolean
  logout: () => void
  requestReset: (email: string) => any
  resetPassword: (token: string, password: string) => boolean
  addNotifierTask: (message: SnackbarMessage) => void
  socketHandler: SocketHandler
}

const useAuth = (): Auth => React.useContext(AuthContext) as Auth

export { AuthProvider, useAuth }
